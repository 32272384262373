(function($) {

  $(document).ready(function() {


    // Toggle the contents inside the sidebar
    $('.js-layout-sidebar-toggle').click(function() {
      $(this).parent().toggleClass('is-open');
    });

    
  });

})(jQuery);
